<template>
    <v-dialog
            persistent
            v-model="editDialog"
            transition="dialog-bottom-transition"
            overlay=false
            scrollable
            width="500px"
            min-width="500px"
            max-width="600px"
    >
        <v-card>
            <v-card-title class="success white--text">{{ $t('message.updateBundle') + ": " + bundleNo }}</v-card-title>
            <v-card-text>
                <v-overlay
                        :value="loading.stock"
                        absolute
                        opacity="0.15"
                >
                    <v-row>
                        <v-col class="text-center">
                            <v-progress-circular
                                    color="primary"
                                    indeterminate
                                    size="40"
                                    width="6"
                            />
                        </v-col>
                    </v-row>
                </v-overlay>
                <v-form lazy-validation ref="stockBundleForm" v-model="validForm">
                    <v-layout row pt-3>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.bundleNo') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-text-field
                                    :value="Stock__bundleno"
                                    :rules="[...validationRules.required, ...validationRules.plainTextWithNumberAndSymbols]"
                                    autocomplete="off"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    solo
                                    @change="Stock__bundleno = $event"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.product') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-autocomplete
                                    :items="allProductGroups"
                                    :rules="[...validationRules.required]"
                                    :placeholder="$t('message.startTypingToSearch')"
                                    autocomplete="off"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    item-value="ProductGroup.id"
                                    item-text="ProductGroup.title"
                                    menu-props="auto"
                                    solo
                                    v-model="Stock__productgroup_id"
                                    @change="filterOtherOptions($event)"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.species') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-autocomplete
                                    :items="filteredSpecies"
                                    :rules="[...validationRules.required]"
                                    :placeholder="$t('message.startTypingToSearch')"
                                    autocomplete="off"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    item-value="Species.id"
                                    item-text="Species.title"
                                    menu-props="auto"
                                    solo
                                    v-model="Stock__species_id"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.certification') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-select
                                    :items="allCertifications"
                                    :value="Stock__certification_id"
                                    autocomplete="off"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    item-text="Certification.title"
                                    item-value="Certification.id"
                                    menu-props="auto"
                                    solo
                                    v-model="Stock__certification_id"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.grade') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-autocomplete
                                    :items="filteredGrades"
                                    :placeholder="$t('message.startTypingToSearch')"
                                    autocomplete="off"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    item-text="Grade.title"
                                    item-value="Grade.id"
                                    menu-props="auto"
                                    solo
                                    v-model="Stock__grade_id"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.spec') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-autocomplete
                                    :items="filteredSpecs"
                                    :placeholder="$t('message.startTypingToSearch')"
                                    autocomplete="off"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    item-text="Spec.title"
                                    item-value="Spec.id"
                                    menu-props="auto"
                                    solo
                                    v-model="Stock__spec_id"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.mc') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-select
                                    :items="allMoistureContent"
                                    :value="Stock__mc_id"
                                    autocomplete="off"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    item-text="Mc.title"
                                    item-value="Mc.id"
                                    menu-props="auto"
                                    solo
                                    v-model="Stock__mc_id"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.thickness') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-autocomplete
                                    :items="filteredThickness"
                                    :placeholder="$t('message.startTypingToSearch')"
                                    autocomplete="off"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    item-text="Thickness.title"
                                    item-value="Thickness.id"
                                    menu-props="auto"
                                    solo
                                    v-model="Stock__thickness_id"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.width') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <div class="d-flex flex-row">
                                <v-text-field
                                        :value="Stock__width"
                                        autocomplete="off"
                                        class="force-text-left"
                                        clearable
                                        dense
                                        hide-details="auto"
                                        solo
                                        @change="Stock__width = $event"
                                />
                            </div>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.length') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <div class="d-flex flex-row">
                                <v-text-field
                                        :value="Stock__length"
                                        autocomplete="off"
                                        class="force-text-left"
                                        clearable
                                        dense
                                        hide-details="auto"
                                        solo
                                        @change="Stock__length = $event"
                                />
                            </div>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.pcs') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-text-field
                                    :value="Stock__piece"
                                    :rules="[...validationRules.required, ...validationRules.number]"
                                    autocomplete="off"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details
                                    solo
                                    type="number"
                                    @change="Stock__piece = $event"
                            />
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.quantity') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <div class="d-flex flex-row align-items-center">
                                <v-text-field
                                        :value="Stock__volume"
                                        :rules="[...validationRules.required, ...validationRules.amount, ...validationRules.minimumZero]"
                                        autocomplete="off"
                                        class="force-text-left"
                                        clearable
                                        dense
                                        hide-details="auto"
                                        solo
                                        @change="Stock__volume = $event"
                                />
                                <v-select
                                        :items="filteredUnits"
                                        :rules="[...validationRules.required]"
                                        :value="Stock__soldmeasurement_id"
                                        class="ml-2"
                                        clearable
                                        dense
                                        hide-details="auto"
                                        item-value="Measurement.id"
                                        item-text="Measurement.title"
                                        solo
                                        v-model="Stock__soldmeasurement_id"
                                />
                            </div>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.buyingPriceOriginal') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <div class="d-flex flex-row align-items-center" v-if="stockOrderCurrencyCode === contractCurrencyCode">
                                <v-text-field
                                        :rules="[...validationRules.amount, ...validationRules.minimumZero]"
                                        :value="Stock__costprice"
                                        autocomplete="off"
                                        class="force-text-left"
                                        dense
                                        hide-details="auto"
                                        solo
                                        @change="Stock__costprice = $event"
                                />
                                <span class="font-md ml-3">{{ stockOrderCurrencyCode }}</span>
                            </div>
                            <div  class="d-flex flex-row align-items-center" v-else>
                                <v-text-field
                                    :rules="[...validationRules.amount, ...validationRules.minimumZero]"
                                    :value="Stock__costPriceOriginal"
                                    autocomplete="off"
                                    class="force-text-left"
                                    dense
                                    hide-details="auto"
                                    solo
                                    @change="Stock__costPriceOriginal = $event"
                                />
                                <span class="font-md ml-3">{{ contractCurrencyCode }}</span>
                            </div>
                        </v-flex>
                    </v-layout>
                </v-form>
            </v-card-text>
            <v-card-actions class="pt-0">
                <v-btn color="default" small @click="editDialog = false" >{{$t('message.dismiss')}}</v-btn>
                <v-btn
                        :loading="loading.save"
                        color="info"
                        small
                        @click="saveBundle()"
                >{{ $t('message.save') }}</v-btn>
                <v-spacer/>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import {mapFields} from "vuex-map-fields";
    import SimpleAlert from "Components/Appic/SimpleAlert";
    import {mapFieldsPrefixed} from "Helpers/vuex-map-fields-prefixed";
    import {validationRules} from "@/store/modules/appic/constants";
    export default {
        name: "UpdateBundleV2",
        props: ['bundleId','bundleNo','dialog','dialog-closed','update-done'],
        data(){
            return {
                dialogs: {
                    error: false,
                    error_message: ""
                },
                edit_dialog: false,
                filteredGrades: [],
                filteredSpecs: [],
                filteredSpecies: [],
                filteredThickness: [],
                filteredUnits: [],
                loading: {
                    stock: false,
                    save: false
                },
                // rules: {
                //     bundleNo: v => !!v || this.$t('message.required'),
                //     piece: v => !!v || this.$t('message.required'),
                //     product: v => !!v || this.$t('message.required'),
                //     species: v => !!v || this.$t('message.required'),
                //     unit: v => !!v || this.$t('message.required'),
                //     volume: v => !!v || this.$t('message.required')
                // },
                validForm: false
            }
        },
        computed: {
            validationRules() {
                return validationRules
            },
            statePrefix() {
                return 'update'
            },
            ...mapFieldsPrefixed('stock',{
                Stock__Contract__currency_id: 'Stock.Contract.currency_id',
                Stock__bundleno: 'Stock.bundleno',
                Stock__certification_id: 'Stock.certification_id',
                Stock__costprice: 'Stock.costprice',
                Stock__costPriceOriginal: 'Stock.costPriceOriginal',
                Stock__grade_id: 'Stock.grade_id',
                Stock__length: 'Stock.length',
                Stock__mc_id: 'Stock.mc_id',
                Stock__piece: 'Stock.piece',
                Stock__productgroup_id: 'Stock.productgroup_id',
                Stock__salesdescription: 'Stock.salesdescription',
                Stock__soldmeasurement_id: 'Stock.soldmeasurement_id',
                Stock__spec_id: 'Stock.spec_id',
                Stock__species_id: 'Stock.species_id',
                Stock__thickness_id: 'Stock.thickness_id',
                Stock__volume: 'Stock.volume',
                Stock__width: 'Stock.width',
                Stock__StockOrder__ms_id: 'Stock.StockOrder.ms_id',
                Stock__StockOrder__currency_id: 'Stock.StockOrder.currency_id'
            },'statePrefix'),
            ...mapGetters('certification', {
                allCertifications: 'allCertifications',
            }),
            ...mapGetters('currency',{
                allCurrencies: 'allCurrencies'
            }),
            ...mapGetters('grade',{
                allGrades: 'allGrades',
                findGradeById: 'findGradeById'
            }),
            ...mapGetters('mc', {
                allMoistureContent: 'allMoistureContent'
            }),
            ...mapGetters('productgroup', {
                allProductGroups: 'allProductGroups',
            }),
            ...mapGetters('species', {
                allSpecies: 'allSpecies',
                findSpeciesById: 'findSpeciesById'
            }),
            ...mapGetters('spec',{
                allSpecs: 'allSpecs',
                allCleanedSpecs: 'allCleanedSpecs',
                findSpecById: 'findSpecById'
            }),
            ...mapGetters('thickness', {
                allThickness: 'allThickness'
            }),
            ...mapGetters([
                'uofmQty',
                'uofmDimensions',
                'validationRules'
            ]),
            editDialog: {
                get() {
                    return this.edit_dialog;
                },
                set(value){
                    this.edit_dialog = value
                    if(value === false) {
                        this.$emit('dialog-closed')
                    }
                }
            },
            contractCurrencyCode () {
                const currency = this.allCurrencies.find(c => c.Currency.id === this.Stock__Contract__currency_id)
                if(currency) return currency.Currency.code
                return ''
            },
            stockOrderCurrencyCode () {
                const currency = this.allCurrencies.find(c => c.Currency.id === this.Stock__StockOrder__currency_id)
                if(currency) return currency.Currency.code
                return ''
            }
        },
        methods: {
            ...mapActions('certification', {
                getAllCertifications: 'getAllCertifications'
            }),
            ...mapActions('currency', {
                getAllCurrencies: 'getAllCurrencies'
            }),
            ...mapActions('grade', {
                getAllGrades: 'getAllGrades'
            }),
            ...mapActions('mc', {
                getAllMoistureContent: 'getAllMoistureContent'
            }),
            ...mapActions('productgroup', {
                getAllProductGroups: 'getAllProductGroups'
            }),
            ...mapActions('spec', {
                getAllSpecs: 'getAllSpecs',
                getAllCleanedSpecs: 'getAllCleanedSpecs'
            }),
            ...mapActions('species', {
                getAllSpecies: 'getAllSpecies'
            }),
            ...mapActions('stock',{
                getBundleToUpdateById: 'getBundleToUpdateById',
                resetBundleToUpdate: 'resetBundleToUpdate',
                updateBundle: 'updateBundle'
            }),
            ...mapActions('thickness', {
                getAllThickness: 'getAllThickness'
            }),
            filterGradesByProduct(productId){
                return new Promise((resolve, reject) => {
                    try {
                        const filteredGrades = this.allGrades.filter(grade => {
                            return grade.Grade.productgroups.includes(productId)
                        })
                        if(filteredGrades.length > 0) {
                            resolve(filteredGrades)
                        } else {
                            resolve([])
                        }
                    } catch(error){
                        reject(error)
                    }
                })
            },
            filterSpeciesByProduct(productId){
                return new Promise((resolve, reject) => {
                    try {
                        const filteredSpecies = this.allSpecies.filter(species => {
                            return species.Species.productgroups.includes(productId)
                        })
                        if (filteredSpecies.length > 0) {
                            resolve(filteredSpecies)
                        } else {
                            resolve([])
                        }
                    } catch(error){
                        reject(error)
                    }
                })
            },
            filterSpecsByProduct(productId){
                return new Promise((resolve, reject) => {
                    try {
                        const filteredSpecs = this.allCleanedSpecs.filter(spec => {
                            return spec.Spec.productgroups.includes(productId)
                        })
                        if(filteredSpecs.length > 0) {
                            resolve(filteredSpecs)
                        } else {
                            resolve([])
                        }
                    } catch(error){
                        reject(error)
                    }
                })
            },
            filterOtherOptions(productId) {
                this.filterSpeciesByProduct(productId)
                    .then((species) => {
                        this.filteredSpecies = species
                        this.filterGradesByProduct(productId)
                            .then((grades) => {
                                this.filteredGrades = grades
                                this.filterSpecsByProduct(productId)
                                    .then((specs) => {
                                        this.filteredSpecs = specs
                                    })
                                    .catch((error) => {
                                        this.$toast.error(error,
                                            {
                                                classes: ['icon-float-left'],
                                                icon: 'error_outline'
                                            }
                                        )
                                    })
                            })
                            .catch((error) => {
                                this.$toast.error(error,
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                            })
                    })
                    .catch((error) => {
                        this.$toast.error(error,
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    })
            },
            filterThickness(ms) {
                return new Promise((resolve, reject) => {
                    try {
                        const various = this.allThickness.find(t => {
                            return t.Thickness.ms === ms && t.Thickness.title === 'various'
                        })
                        const thicknesses = this.allThickness.filter(t => {
                            return t.Thickness.ms === ms && t.Thickness.title !== 'various'
                        })
                        thicknesses.sort((a,b) => {
                            let strA = a + ''
                            let strB = b + ''
                            return strA.localeCompare(strB, undefined, { numeric: true })
                        })
                        if(various){
                            resolve( [various,...thicknesses])
                        } else {
                            resolve(thicknesses)
                        }
                    } catch(error) {
                        reject(error)
                    }
                })
            },
            filterUnits(ms) {
                return new Promise((resolve, reject) => {
                    try {
                        const filteredUnits = this.uofmQty.filter(u => {
                            return u.Measurement.system === ms || u.Measurement.system == null
                        })
                        if(filteredUnits.length > 0) {
                            resolve(filteredUnits)
                        } else {
                            resolve([])
                        }
                    } catch(error) {
                        reject(error)
                    }
                })
            },
            getUofmDimension( uofm_id ) {
                let uofm = this.uofmDimensions.find(u => u.Measurement.id === uofm_id)?.Measurement.title
                return uofm;
            },
            getUofmQty( uofm_id ) {
                let uofm = this.uofmQty.find(u => u.Measurement.id === uofm_id)?.Measurement.title
                return uofm;
            },
            saveBundle () {
                if(this.$refs.stockBundleForm.validate()) {
                    this.loading.save = true
                    this.updateBundle()
                        .then((status) => {
                            if (status === 'done') {
                                this.$toast.success(this.$t('message.successes.bundleUpdated'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'check_circle_outline'
                                    }
                                )
                                this.loading.save = false
                                this.$emit('update-done')
                            } else {
                                this.$toast.error(this.$t('message.errors.bundleNotUpdated'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                                this.loading.save = false
                            }
                        })
                        .catch(() => {
                            this.$toast.error(this.$t('message.errors.bundleNotUpdated'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.save = false
                        })
                } else {
                    this.$toast.error(this.$t('message.errors.bundleNotUpdated'),
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                }
            },
        },
        watch: {
            dialog(value) {
                if(value === true){
                    this.loading.stock = true
                    this.getBundleToUpdateById( this.bundleId )
                        .then(() => {
                            this.filterUnits(this.Stock__StockOrder__ms_id)
                                .then((units) => {
                                    this.filteredUnits = units
                                    this.filterThickness(this.Stock__StockOrder__ms_id)
                                        .then((thickness) => {
                                            this.filteredThickness = thickness
                                            this.filterOtherOptions(this.Stock__productgroup_id)
                                            this.loading.stock = false
                                        })
                                        .catch((error) => {
                                            this.loading.stock = false
                                            this.$toast.error(error,
                                                {
                                                    classes: ['icon-float-left'],
                                                    icon: 'error_outline'
                                                }
                                            )
                                        })
                                })
                                .catch((error) => {
                                    this.loading.stock = false
                                    this.$toast.error(error,
                                        {
                                            classes: ['icon-float-left'],
                                            icon: 'error_outline'
                                        }
                                    )
                                })
                        })
                        .catch((error) => {
                            this.loading.stock = false
                            this.$toast.error(error,
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        })
                } else {
                    this.resetBundleToUpdate()
                }
                this.edit_dialog = value
            }
        },
        created () {
            // this.resetSalesOrderItems(this.statePrefix)
            if(this.allSpecies.length === 0) this.getAllSpecies()
            if(this.allGrades.length === 0) this.getAllGrades()
            if(this.allProductGroups.length === 0) this.getAllProductGroups()
            if(this.allCurrencies.length === 0) this.getAllCurrencies()
            if(this.allSpecs.length === 0) this.getAllSpecs()
            if(this.allThickness.length === 0) this.getAllThickness()
        },
        mounted() {
            this.$store.watch(
                function (state) {
                    return state.appic.stock.update.Stock
                },
                () => {
                    // this.updatedStockOrderUnsaved = true
                },
                {deep: true}
            )
        }
    }
</script>

<style>

</style>